@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,700;6..12,800;6..12,1000");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  box-sizing: border-box;
}

/* html {
  background-color: rgb(55 65 81);
} */

body {
  font-family: "Nunito Sans", sans-serif;
}

nav {
  font-family: "Playfair Display", serif;
}

ul {
  list-style-type: none;
}

/* .ball {
  animation: float 3.5s ease-in-out infinite;
  height: 160px;
  width: 160px;
  border-radius: 50%;
  position: relative;

  background: radial-gradient(
    circle at 75% 30%,
    white 5px,
    aqua 8%,
    darkblue 60%,
    aqua 100%
  );
  box-shadow: inset 0 0 20px #fff, inset 10px 0 46px #eaf5fc,
    inset 88px 0px 60px #c2d8fe, inset -20px -60px 100px #fde9ea,
    inset 0 50px 140px #fde9ea, 0 0 90px #fff;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-80px);
  }
  100% {
    transform: translatey(0px);
  }
} */

/* .shadow {
  background: #b490b2;
  width: 130px;
  height: 30px;
  top: 70%;
  animation: expand 4s infinite;
  position: absolute;
  border-radius: 50%;
}
@keyframes expand {
  0%,
  100% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 500px) {
  .ball {
    animation: float 4s ease-in-out infinite;
    height: 120px;
    width: 120px;

    background: radial-gradient(
      circle at 65% 35%,
      white 5px,
      aqua 15%,
      darkblue 50%,
      aqua 100%
    );
    box-shadow: inset 0 -20px 30px #fff, inset 40px 0 46px #eaf5fc,
      inset 58px 0px 60px #c2d8fe, inset -8px -30px 100px #fde9ea,
      inset 0 20px 110px #fde9ea, 0 0 90px #fff;
  }
  .shadow {
    width: 90px;
    height: 20px;
    top: 78%;
    animation: expand 5s infinite;
  }

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-50px);
    }
    100% {
      transform: translatey(0px);
    }
  }
} */

@media (prefers-color-scheme: dark) {
  body {
    background-color: rgb(243 244 246);
  }
}

@media (prefers-color-scheme: light) {
  body {
    background-color: rgb(243 244 246);
  }
}
